<template>
  <div>
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("Employees") }}
            <span class="d-block text-muted pt-2 font-size-sm">{{ $t("Employee management") }}</span>
          </h3>
        </div>
        <div class="card-toolbar">
          <euro-select v-model="activeFilter"
            :options="[{ text: $t('All'), value: null }, { text: $t('Employee'), value: true }, { text: $t('Ex Employee'), value: false }]"
            class="min-w-300px mb-0 mr-3">
          </euro-select>
          <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
          </euro-input>
          <router-link :to="{ name: 'create-employee' }" class="btn btn-light-primary font-weight-bolder mr-4">
            <div class="d-flex align-items-center">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
              </span>
              {{ $t("New Employee") }}
            </div>
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
          :per-page-options="perPageOptions" :filter="searchFilter">
          <!-- CELLS -->
          <template #[`cell.persona`]="{ item }">
            <div class="d-flex align-items-center">
              <div v-if="!item.persona.avatar_display" class="symbol symbol-40 symbol-light-primary flex-shrink-0">
                <span class="symbol-label font-size-h4 font-weight-bold">{{ item.persona.name[0] }}{{
              item.persona.surname[0] }}</span>
              </div>
              <div v-else class="symbol symbol-40 symbol-light-primary flex-shrink-0">
                <img :src="item.persona.avatar_display" alt="avatar" />
              </div>
              <div class="ml-3">
                <div class="font-weight-bold text-capitalize">{{ item.persona.name }} {{ item.persona.surname }}</div>
                <div class="text-muted font-size-xs">{{ item.persona.email }}</div>
                <div v-if="item.persona.date_birth" class="text-muted  font-size-xs">{{
              DateService.format(item.persona.date_birth, dateFormat) }}</div>
              </div>
            </div>
          </template>
          <template #[`cell.type`]="{ item }">
            <span v-if="item.agency" class="font-weight-bold label label-inline label-light-warning">
              {{ $t("Temporary Worker") }}
            </span>
            <span v-else-if="item.employeecontract_set?.length && !item.is_active"
              class="font-weight-bold label label-inline label-light-danger">
              {{ $t("Ex Employee") }}
            </span>
            <span v-else class="font-weight-bold label label-inline label-light-success">{{ $t("Employee") }}
            </span>
          </template>

          <template #[`cell.start_date`]="{ item }">
            <div>
              <span v-if="!buttonDisabled && firstEmployeeContract(item)?.start_date" class="font-weight-bolder text-muted" >
                {{ DateService.format(firstEmployeeContract(item)?.start_date, dateFormat) }}
              </span>
              <span v-else-if="!buttonDisabled"></span>
              <span v-else>*****</span>
            </div>
          </template>

          <template #[`cell.end_date`]="{ item }">
            <div>
              <span v-if="!buttonDisabled && firstEmployeeContract(item)?.end_date" class="font-weight-bolder text-muted">
                {{ DateService.format(firstEmployeeContract(item)?.end_date, dateFormat) }}
              </span>
              <span v-else-if="!buttonDisabled && firstEmployeeContract(item)?.start_date" class="font-weight-bolder text-muted">{{ $t("Indeterminate") }}</span>
              <span v-else-if="!buttonDisabled"></span>
              <span v-else>*****</span>
            </div>
          </template>

          <template #[`cell.CCNL`]="{ item }">
            <div v-if="!buttonDisabled" >
              <div v-if="firstEmployeeContract(item)?.national_contract?.name && item?.is_active"
                class="d-flex align-items-center">
                <div v-if="firstEmployeeContract(item)?.national_contract?.country">
                  <country-flag class="mr-4"
                    :country-iso="firstEmployeeContract(item)?.national_contract.country"></country-flag>
                </div>
                <span class="national-contract">{{ firstEmployeeContract(item)?.national_contract.name }}</span>
              </div>
              <div v-else class="d-flex align-items-center">
                <router-link :to="{ name: 'associate-contract', params: { ppId: item.persona.id } }"
                  class="btn btn-xs btn-light-primary font-weight-bolder">
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="/media/svg/icons/Files/File.svg" />
                    </span>
                    {{ $t("Add contract") }}
                  </div>
                </router-link>
              </div>
            </div>
            <div v-else>
              <span>*****</span>
            </div>
          </template>

          <template #[`cell.level`]="{ item }">
            <div v-if="!buttonDisabled">
              <div v-if="firstEmployeeContract(item)?.national_contract_level">
                <span>{{ firstEmployeeContract(item)?.national_contract_level?.level_name }}</span>
              </div>
            </div>
            <div v-else>
              <span>*****</span>
            </div>
          </template>

          <template #[`cell.actions`]="{ item }">

            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" :disabled="!item?.persona.id"
              @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/Edit.svg" />
              </span>
            </b-button>
          </template>
        </datatable>
      </div>
    </div>
    <router-view name="profile-detail-legal-documents"></router-view>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import EmployeeService from "@/core/services/employee/employee.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import icons from "@/core/config/icons.js";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { backendErrorSwal } from "@/core/helpers/swal";


export default {
  components: {
    Datatable,
    CountryFlag,
  },
  data() {
    return {
      icons,
      DateService,
      fields: [
        { label: this.$t("Employee"), key: "persona", sortable: true },
        { label: this.$t("Type"), key: "type" },
        { label: this.$t("CCNL"), key: "CCNL", sortable: true, tdClass: "national-contract" },
        { label: this.$t("Level"), key: "level", sortable: true, tdClass: "national-contract" },
        { label: this.$t("Start date"), key: "start_date", sortable: true },
        { label: this.$t("End date"), key: "end_date", sortable: true },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      total: 0,
      searchFilter: "",
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      activeFilter: null,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "isAfterSale", "getCurrentManagedCompany", "isApStaff"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
    },

    buttonDisabled() {
      return this.isAfterSale || (!this.isApStaff && !this.getCurrentManagedCompany?.contract_permission);
    },
    
    firstEmployeeContract() {
      return (item) => {
        if (Array.isArray(item.employeecontract_set) && item.employeecontract_set.length > 0) {
          return item.employeecontract_set[0];
        }
        return null; 
      };
    },
  },

  watch: {
    activeFilter: function () {
      this.$refs.datatableRef.refresh();
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Employee") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    itemProvider(ctx, callback) {
      EmployeeService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "start_date",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'persona.id,persona.name,persona.surname,persona.avatar_display,persona.date_birth,persona.email,employeecontract_set.national_contract.name,employeecontract_set.start_date,employeecontract_set.end_date,employeecontract_set.national_contract_level.level_name,is_active,agency',
        active: this.activeFilter
      })
        .then(res => {
          this.total = res.count;
          callback(res.results);
        })
        .catch((err) => {
          backendErrorSwal(err, "Failed to load items");
          callback([]);
        });

      return null;
    },

    edit(item) {
      this.$router
        .push({
          name: "detail-physical-employment",
          params: { userID: item.persona.id },
        })
    },
  },
};
</script>

<style scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}

.max-width-column {
  max-width: 200px;
}

::v-deep .national-contract {
  max-width: 32ch;
}

::v-deep .country-flag {
  min-width: 16px;
  max-width: 16px;
}

.btn.btn-outline-light {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:focus span,
.btn.btn-outline-light:hover i::before,
.btn.btn-outline-light:focus i::before {
  color: var(--primary);
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
