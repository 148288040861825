var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header flex-wrap border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employees")) + " "), _c('span', {
    staticClass: "d-block text-muted pt-2 font-size-sm"
  }, [_vm._v(_vm._s(_vm.$t("Employee management")))])])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-select', {
    staticClass: "min-w-300px mb-0 mr-3",
    attrs: {
      "options": [{
        text: _vm.$t('All'),
        value: null
      }, {
        text: _vm.$t('Employee'),
        value: true
      }, {
        text: _vm.$t('Ex Employee'),
        value: false
      }]
    },
    model: {
      value: _vm.activeFilter,
      callback: function callback($$v) {
        _vm.activeFilter = $$v;
      },
      expression: "activeFilter"
    }
  }), _c('euro-input', {
    staticClass: "min-w-300px mb-0 mr-0",
    attrs: {
      "placeholder": _vm.$t('Search'),
      "debounce": 300
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  }), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder mr-4",
    attrs: {
      "to": {
        name: 'create-employee'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Add-user.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("New Employee")) + " ")])])], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.searchFilter
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.persona",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [!item.persona.avatar_display ? _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('span', {
          staticClass: "symbol-label font-size-h4 font-weight-bold"
        }, [_vm._v(_vm._s(item.persona.name[0]) + _vm._s(item.persona.surname[0]))])]) : _c('div', {
          staticClass: "symbol symbol-40 symbol-light-primary flex-shrink-0"
        }, [_c('img', {
          attrs: {
            "src": item.persona.avatar_display,
            "alt": "avatar"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bold text-capitalize"
        }, [_vm._v(_vm._s(item.persona.name) + " " + _vm._s(item.persona.surname))]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(item.persona.email))]), item.persona.date_birth ? _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(_vm.DateService.format(item.persona.date_birth, _vm.dateFormat)))]) : _vm._e()])])];
      }
    }, {
      key: "cell.type",
      fn: function fn(_ref2) {
        var _item$employeecontrac;
        var item = _ref2.item;
        return [item.agency ? _c('span', {
          staticClass: "font-weight-bold label label-inline label-light-warning"
        }, [_vm._v(" " + _vm._s(_vm.$t("Temporary Worker")) + " ")]) : (_item$employeecontrac = item.employeecontract_set) !== null && _item$employeecontrac !== void 0 && _item$employeecontrac.length && !item.is_active ? _c('span', {
          staticClass: "font-weight-bold label label-inline label-light-danger"
        }, [_vm._v(" " + _vm._s(_vm.$t("Ex Employee")) + " ")]) : _c('span', {
          staticClass: "font-weight-bold label label-inline label-light-success"
        }, [_vm._v(_vm._s(_vm.$t("Employee")) + " ")])];
      }
    }, {
      key: "cell.start_date",
      fn: function fn(_ref3) {
        var _vm$firstEmployeeCont, _vm$firstEmployeeCont2;
        var item = _ref3.item;
        return [_c('div', [!_vm.buttonDisabled && (_vm$firstEmployeeCont = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont !== void 0 && _vm$firstEmployeeCont.start_date ? _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format((_vm$firstEmployeeCont2 = _vm.firstEmployeeContract(item)) === null || _vm$firstEmployeeCont2 === void 0 ? void 0 : _vm$firstEmployeeCont2.start_date, _vm.dateFormat)) + " ")]) : !_vm.buttonDisabled ? _c('span') : _c('span', [_vm._v("*****")])])];
      }
    }, {
      key: "cell.end_date",
      fn: function fn(_ref4) {
        var _vm$firstEmployeeCont3, _vm$firstEmployeeCont4, _vm$firstEmployeeCont5;
        var item = _ref4.item;
        return [_c('div', [!_vm.buttonDisabled && (_vm$firstEmployeeCont3 = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont3 !== void 0 && _vm$firstEmployeeCont3.end_date ? _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format((_vm$firstEmployeeCont4 = _vm.firstEmployeeContract(item)) === null || _vm$firstEmployeeCont4 === void 0 ? void 0 : _vm$firstEmployeeCont4.end_date, _vm.dateFormat)) + " ")]) : !_vm.buttonDisabled && (_vm$firstEmployeeCont5 = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont5 !== void 0 && _vm$firstEmployeeCont5.start_date ? _c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.$t("Indeterminate")))]) : !_vm.buttonDisabled ? _c('span') : _c('span', [_vm._v("*****")])])];
      }
    }, {
      key: "cell.CCNL",
      fn: function fn(_ref5) {
        var _vm$firstEmployeeCont6, _vm$firstEmployeeCont7, _vm$firstEmployeeCont8, _vm$firstEmployeeCont9, _vm$firstEmployeeCont10, _vm$firstEmployeeCont11;
        var item = _ref5.item;
        return [!_vm.buttonDisabled ? _c('div', [(_vm$firstEmployeeCont6 = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont6 !== void 0 && (_vm$firstEmployeeCont7 = _vm$firstEmployeeCont6.national_contract) !== null && _vm$firstEmployeeCont7 !== void 0 && _vm$firstEmployeeCont7.name && item !== null && item !== void 0 && item.is_active ? _c('div', {
          staticClass: "d-flex align-items-center"
        }, [(_vm$firstEmployeeCont8 = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont8 !== void 0 && (_vm$firstEmployeeCont9 = _vm$firstEmployeeCont8.national_contract) !== null && _vm$firstEmployeeCont9 !== void 0 && _vm$firstEmployeeCont9.country ? _c('div', [_c('country-flag', {
          staticClass: "mr-4",
          attrs: {
            "country-iso": (_vm$firstEmployeeCont10 = _vm.firstEmployeeContract(item)) === null || _vm$firstEmployeeCont10 === void 0 ? void 0 : _vm$firstEmployeeCont10.national_contract.country
          }
        })], 1) : _vm._e(), _c('span', {
          staticClass: "national-contract"
        }, [_vm._v(_vm._s((_vm$firstEmployeeCont11 = _vm.firstEmployeeContract(item)) === null || _vm$firstEmployeeCont11 === void 0 ? void 0 : _vm$firstEmployeeCont11.national_contract.name))])]) : _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('router-link', {
          staticClass: "btn btn-xs btn-light-primary font-weight-bolder",
          attrs: {
            "to": {
              name: 'associate-contract',
              params: {
                ppId: item.persona.id
              }
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Files/File.svg"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t("Add contract")) + " ")])])], 1)]) : _c('div', [_c('span', [_vm._v("*****")])])];
      }
    }, {
      key: "cell.level",
      fn: function fn(_ref6) {
        var _vm$firstEmployeeCont12, _vm$firstEmployeeCont13, _vm$firstEmployeeCont14;
        var item = _ref6.item;
        return [!_vm.buttonDisabled ? _c('div', [(_vm$firstEmployeeCont12 = _vm.firstEmployeeContract(item)) !== null && _vm$firstEmployeeCont12 !== void 0 && _vm$firstEmployeeCont12.national_contract_level ? _c('div', [_c('span', [_vm._v(_vm._s((_vm$firstEmployeeCont13 = _vm.firstEmployeeContract(item)) === null || _vm$firstEmployeeCont13 === void 0 ? void 0 : (_vm$firstEmployeeCont14 = _vm$firstEmployeeCont13.national_contract_level) === null || _vm$firstEmployeeCont14 === void 0 ? void 0 : _vm$firstEmployeeCont14.level_name))])]) : _vm._e()]) : _c('div', [_c('span', [_vm._v("*****")])])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details'),
            "disabled": !(item !== null && item !== void 0 && item.persona.id)
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Edit.svg"
          }
        })], 1)])];
      }
    }], null, true)
  })], 1)]), _c('router-view', {
    attrs: {
      "name": "profile-detail-legal-documents"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }